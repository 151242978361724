.Wrapper {
  margin: 0 auto;
  max-width: 92vw;
}

.ViewContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  gap: 5px;
}

.Button {
  background-color: #f4fefe;
  color: #0B4A6F;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Button:hover {
  background-color: #0B4A6F;
  color: #f4fefe;
}

.Switch_Toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

/* .Switch_Toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .Slider {
  background-color: #2196f3;
}

input:focus + .Slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .Slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
} */

.rows > rect {
  fill: #ffffff;
}

.rows > rect:hover {
  fill: #ebecef;
}

.rows > rect:nth-child(even) {
  fill: #f4f5f7;
}

.rows > rect:nth-child(even):hover {
  fill: #ebecef;
}

.calendar > rect {
  fill: #f4f5f7;
}
