.courseImg{
  width: 20vw;
  height: 30vh;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

/* Text editor class name style for contributions */
.rdw-dropdown-selectedtext{
  font-size: 18px;

}
.rdw-dropdownoption-default{
  font-size: 14px;
}
.rdw-dropdown-selectedtext{
  font-size: 18px;
}
.rdw-editor-toolbar{
  border-radius: 10px;
}
.DraftEditor-root{
  border-radius: 10px;
  padding: 10px;
}

/* 
.MuiDataGrid-columnHeaders {
  background-color: rgb(216, 216, 216),
}
.MuiDataGrid-columnHeaderTitle {
  color: "black",
 
}
.MuiDataGrid-columnHeader {
  background-color: "rgb(216, 216, 216)",
}
.MuiDataGrid-columnHeader--sortable:hover {
  background-color: "rgb(200, 200, 200)",
} */





/* For navbar css start */

.main-nav .main-nav-btn {
color: #37668F;
background-color: #f0f0f0;
margin: 5px;
border-radius: 4px;
margin-right: 2%;

}

.main-nav .mainnav-active-btn {
color: #37668F;
background-color: #f0f0f0;
margin: 5px;
border-radius: 4px;
margin-right: 2%;

}

/* Add styles for sub navigation buttons */
.sub-nav .sub-nav-btn {
color: #000;
background-color: #e0e0e0;
margin: 5px;
border-radius: 4px;
margin-right: 2%;
}

.sub-nav .subnav-active-btn {
color: #37668F;
background-color: #f0f0f0;
margin: 5px;
border-radius: 4px;
margin-right: 2%;
}

/* For navbar css end */





/* Below css from vliv */


.signin-main {
width: 100vw;
height: 100vh;
background-image: url("../assets/signInBackground.webp");
background-size: cover;
background-repeat: no-repeat;
background-position: center;
display: flex;
justify-content: center;
align-items: center;
gap: 2rem;
flex-wrap: wrap;
}

.logo_img {
width: 30%;
height: 20%;
}


.signin-left p {
color: white;
font-size: 1.5rem;
margin-top: 2rem;
text-align: center;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
padding: 1.5rem !important;
}

.pointer {
cursor: pointer;
}


/* For canvas chart */
.canvasjs-chart-credit{
display: none;
}










/* overriding mui  */

.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-container--top [role="row"],.css-mbboce-MuiTableRow-root,.css-1ne8j0w,.css-60ds9y-MuiDataGrid-root .MuiDataGrid-container--top [role=row],.css-1ve3l6o .MuiDataGrid-container--top [role="row"] {
background-color: var(--common-bg-color) !important;
/* border-bottom: 1px solid #b9e6fe !important; */
/* margin-bottom: 1.5px !important; */
color: rgba(255, 255, 255, 1);
font-size: 16px !important;

}



.custom-checkbox svg{
  color: black;
}

.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-row , .css-ooo7gw .MuiDataGrid-row {
/* color: #98a2b3 !important; */
color: #1d2939 !important;
background-color: white;
font-size: 14px;
margin-bottom: 2px;
}

.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover,.css-ooo7gw .MuiDataGrid-row:hover{
font-size: large;
background-color: white;
font-size: 15px;
box-shadow: rgba(0, 0, 0, 0.23) 1px 2px 2px !important;
}

/* for table border radius  */
.css-n3fyjk-MuiDataGrid-root ,.css-ooo7gw,.css-60ds9y-MuiDataGrid-root,.css-1ve3l6o {
/* border-color: var(--common-bg-color) !important; */
border-radius: 14px !important;
}

.css-rqglhn-MuiTable-root,.css-1owb465{
border:1px solid #b9e6fe !important ;
border-radius: 4px !important;
}

.css-rqglhn-MuiTable-root,.css-1owb465{
border-collapse: separate !important;
}

.css-13xfq8m-MuiTabPanel-root {
padding: 0 !important;
}

.css-hg18dx-MuiButtonBase-root-MuiTab-root,.css-13cp4kv {
font-size: 1rem !important;
color: #98a2b3 !important;
}

.css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected,.css-13cp4kv.Mui-selected {
color: #026aa2 !important;
background-color: white !important;
border-top-left-radius: 10px !important;
border-top-right-radius: 10px !important;
box-shadow: 0 2px 4px rgba(0, 0, 0.3, 0.3) !important;
}

.css-1897kd5 {
padding: 0 !important;
}

/* here------------------ */

/* No changes needed for layout.css unless targeting MUI components specifically */

.master-heading {
color: #37668f !important;
font-weight: bold !important;
}

.view-left,
.view-right,
.application-status-box {
padding: 6px 12px !important;
border-radius: 10px !important;
background-color: white !important;
}
.view-left:hover,
.view-right:hover,
.application-status-box:hover {
box-shadow: rgba(0, 0, 0, 0.23) 0px 4px 4px !important;
}

.css-yq6j56 {
padding: 12px;
}

.css-ooo7gw .MuiDataGrid-container--top [role="row"],
.css-ooo7gw .MuiDataGrid-container--bottom [role="row"],.css-1ve3l6o .MuiDataGrid-container--top [role="row"] {
background-color: var(--common-bg-color) !important;
/* border-bottom: 1px solid #b9e6fe !important; */
/* margin-bottom: 1.5px !important; */
font-size: 16px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1etx6i0-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input,
.ql-editor,.css-qiwgdb,.css-qiwgdb.MuiSelect-select,.css-1x5jdmq,.css-segi59,.css-1x6bjyf-MuiAutocomplete-root,.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root,.css-z3c6am-MuiFormControl-root-MuiTextField-root,.css-aq7zae-MuiFormControl-root-MuiTextField-root,.css-1ab2xsx,.css-18col2x .MuiOutlinedInput-root,.css-15kq27i,.css-1o6z5ng {
background-color: #fcfcfd !important;
border-radius: 8px !important;
padding: 12px !important;
font-size: 16px !important;
}

.css-1aquho2-MuiTabs-indicator {
background: none !important;
}



.css-i4bv87-MuiSvgIcon-root,.css-vubbuv  {
height: 20px !important;
height: 20px !important;
}

.css-hcdzbm-MuiAutocomplete-root,
.css-fzem9i-MuiAutocomplete-root,.css-ypiqx9-MuiDialogContent-root {
padding: 0 !important;
}

.css-1nar8dr .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
padding: 6px !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled,.css-1ujsas3.Mui-disabled{
background-color: #98A2B3;
color: #E4E7EC;
}


/* --input  */

.css-hcdzbm-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input,.css-1etx6i0-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
padding: 0 !important;
}

.css-hcdzbm-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall,.css-1nar8dr .MuiOutlinedInput-root .MuiAutocomplete-input,.css-1nar8dr .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input{
padding: 10px 15px !important;
}

.css-38raov-MuiButtonBase-root-MuiChip-root,.css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input,.css-1nar8dr .MuiAutocomplete-tag{
  font-size: 16px !important;
}


/* From below joText editor css added (Shyam bhai wala text editor ka css h he ye) */

.jodit-status-bar a.jodit-status-bar-link {
background-color: transparent;
color: rgba(0, 0, 0, .75);
display: none;
}