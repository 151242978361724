/* Theme Rule */
:root {
  --sidebar-bg-color: #fafafa;
  --sidebar-text-color: #344054;
  --sidebar-module-color: #ffa000;
  --sidebar-hover-color: #d98800;
  --sidebar-ho-text-color: #f2f4f7;
  --page-color: #f9dbb5;
  --delete-btn-color: #e70e0a;
  --table-head-color: #e3e7eb;
  --table-bg-color: #fafafa;
  --table-title-color: #1d2939;
  --main-bg-color: #eaecf0;
  --new-row-color: #114388;
  --publish-color: #0ebe08;
  --blue-color: #3342ff;
  --unpaid-color: #29b6f6;
  --print-table-head-color: #42536d;
  --common-bg-color:rgba(55, 102, 143, 1);
  --dark-color: #242424;
  --helper-color: #99b9ff;
  --input-color: #555;
  --login-color: linear-gradient(to bottom,
      #ffd9a4,
      #ffcda1,
      #ffc2a0,
      #ffb7a2,
      #feada5);
  --login-btn-color: #f59e0b;

  --badge-bg-color: #fff2dc;
  --badges-bg-color: #f59e0b;

  --search-pl-color: #555;
  --pagination-no-color: #d6bbfb;
  --pagination-no-active-color: #5119ed;


  /* --header-color: #062C41; */
  /* --savebtn-color: #092C4C; */
  /* --btmbox-shadow: 0px 1px 1px rgba(85, 85, 85, 0.16); */
  /* --sec-nav-btn-color: #494949; */

  /* --eyeicon-color: #9E9FA5; */

  /* Main Background color */
  --bg-color: #F0F9FF;

  --common-table-bg-color:rgba(55, 102, 143, 1);
  /* Header color */
  --header-color: #F4FAFF;
  --header-text-color: #37668F;

  --icon-color: #98A2B3;
  --icon-hover-color: #1D2939;
  --savebtn-color:rgba(55, 102, 143, 1);

  /* Button color; */
  --common-color: #026AA2;
  --commonBtn-color: #0B4A6F;

  /* Login page link color */
  --link-color: #0a58ca;

  /* Dashboard page color */
  --viewall-color: #026AA2;
  --card-name-color: #98A2B3;
  --card-time-color: #344054;
  --common-background-color:#F4FAFF;
}


.common-table-header-bg-color{
  background-color: var(--common-table-bg-color);
}

.common-background-color{
  background-color:var(--common-background-color);
}