.Wrapper {
  margin: 0 auto;
  max-width: 90vw;
  padding: 20px;
  background-color: #f9f9f9;
}

.ViewContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  gap: 5px;
}

.Button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Button:hover {
  background-color: #0056b3;
}

.rows > rect {
  fill: #ffffff;
}

.rows > rect:hover {
  fill: #ebeff5;
}

.rows > rect:nth-child(even) {
  fill: #f4f6f9;
}

.rows > rect:nth-child(even):hover {
  fill: #ebeff5;
}

.calendar > rect {
  fill: #f4f5f7;
}

